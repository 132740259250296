import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import UnorderedList from "~/components/List/Unordered";
import Accordion from "~/components/accordion";

const Page = () => {
  return (
      <LessonLayout
        current_step={-1}
        lesson={"Lesson 3"}
        color={"hs"}
        total_count={10}
        module_title="Will You Buy What They Are Selling?"
      >
        <ContentWrapper>
          <H2 underline>Lesson at a Glance</H2>

          <h2>Lesson Overview</h2>

          <p>In this lesson, students consider the motivations that cause people to post health information online. Students learn some different persuasion strategies that marketers use to influence consumers. Students work in small teams to compose a role-play highlighting one of the strategies intended to further a particular agenda. Students then learn about and practice critical thinking by developing questions about information on a website.</p>

          <h3>Major Concepts</h3>

          <UnorderedList>
            <li>People or companies have different motivations when conveying health information.</li>
            <li>Creators of health information resources use different strategies (such as stirring up emotions, making claim about quality of research supporting claims, causing fear, and so forth) to persuade others about the accuracy or quality of their materials.</li>
            <li>People seeking health information should be aware of potential bias in the resources they find.</li>
            <li>Critical thinking is a skill that allows people to make logical and informed decisions to the best of their abilities. It involves analyzing an issue or situation using facts, data, and evidence—and not opinions or feelings.</li>
            <li>People have the right to question information presented in the media.</li>
          </UnorderedList>

          <h3>Objectives</h3>

          <p>Upon completion of this lesson, students will be able to</p>

          <UnorderedList>
            <li>discuss different motivations used when health information is presented online or in other media,</li>
            <li>recognize some of the most common strategies used to persuade others, and</li>
            <li>ask questions about the quality or relevance of information presented in (or missing from) the media (e.g., an ad).</li>
          </UnorderedList>

          <h2>In Advance</h2>

          <p><span className="italic font-semibold">Estimated time for the lesson: </span>2 45-minute class periods</p>

          <p><span className="italic font-semibold">Screens to project:</span> none</p>

          <p className="italic font-semibold">Materials</p>
          <UnorderedList>
            <li>Students will need the rubrics that they started in Lesson 1 to analyze the CBD websites.</li>
          </UnorderedList>

          <p className="italic font-semibold">Preparation</p>
          <UnorderedList>
            <li>Read through and review information in this lesson.</li>
          </UnorderedList>

          <div className="mt-5">
            <Accordion color="ms" open={true} headerText="Note to teachers">
              <div>
                <p>In Lesson 5, students will use their rubrics to evaluate health-related information of their own choosing. They can find information on the internet, TV, social media, magazines, newspapers, or other sources. At some point during this lesson, you may want to assign students the task of thinking of a health-related question based on their own interests and finding these resources. See Lesson 5 for more information.</p>
              </div>
            </Accordion>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;


